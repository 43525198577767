import CercleBleuAvecNombreProps from "./CercleBleuAvecNombreProps";
import "./style.css";

const CercleBleuAvecNombre = ({ texte }: CercleBleuAvecNombreProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      data-testid="text-svg"
    >
      <circle cx="11" cy="11" r="11" fill="#095797" />
      <text
        className="cercleBleu"
        x="50%"
        y="50%"
        text-anchor="middle"
        dominant-baseline="middle"
        font-size="14"
        fill="white"
        font-weight="bold"
      >
        {texte}
      </text>
    </svg>
  );
};

export default CercleBleuAvecNombre;
