export type SingleRadioProps = {
  label: string;
  checked: boolean;
  id?: string;
  containerClassName?: string;
  labelClassName?: string;
  className?: string;
  style?: object;
  onChange: (arg: any) => void;
};

const SingleRadio: React.FC<SingleRadioProps> = (props) => {
  const {
    label,
    onChange,
    checked,
    containerClassName,
    labelClassName,
    ...rest
  } = props;
  return (
    <div {...rest} className={containerClassName}>
      <input
        type="radio"
        name="options"
        checked={checked}
        onChange={onChange}
      />
      <label className={labelClassName} htmlFor={label} onClick={onChange}>
        {label}
      </label>
    </div>
  );
};

export default SingleRadio;
