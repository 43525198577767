import SectionCaseACocher from "../SectionCaseACocher/SectionCaseACocher";
import ListeSectionCaseACocherProps from "./ListeSectionCaseACocherProps";

const ListeSectionCaseACocher = (props: ListeSectionCaseACocherProps) => {
  return (
    <div>
      {props.options.map((option) => (
        <SectionCaseACocher
          key={option.value}
          id={props.id}
          disabled={option.disabled}
          name={option.name}
          value={option.value}
          checked={option.value === props.selectedOption?.value}
          onChange={() => props.onOptionSelected(option)}
        >
          {option.label}
        </SectionCaseACocher>
      ))}
    </div>
  );
};

export default ListeSectionCaseACocher;
