import React, { useEffect, useState } from "react";
import "./styles.css";
import { LienPiedDePage, PiedDePageProps } from "./PiedDePageProps";
import piedDePageParDefaut from "./PiedDePagePropsParDefaut";
import iconeLienExterne from "../../icones/lien-externe-white.svg";
import iconeLienExterneCouleur from "../../icones/lien-externe-couleur.svg";
import Icone_SAG_securite from "../../icones/Icone_SAG_securite.svg";
import { useTranslation } from "react-i18next";

function TitreAvecOuSansLien(titre: LienPiedDePage) {
  const { t } = useTranslation(["sqin-ux"]);

  if (titre.url) {
    return <h2 className={"titre"}>{t(titre.libelle)}</h2>;
  } else {
    return <h2 className={"titre"}>{t(titre.libelle)}</h2>;
  }
}

const PiedDePage: React.FC<{ customPiedDePage?: PiedDePageProps }> = (
  props
) => {
  const [this_props, set_this_props] = useState(piedDePageParDefaut);
  const { t, i18n } = useTranslation(["sqin-ux"]);
  useEffect(() => {
    if (props.customPiedDePage) {
      set_this_props(props.customPiedDePage);
    }
  }, [props.customPiedDePage]);

  return (
    <footer className={"conteneur-pied-de-page"}>
      <div className={"conteneur-section-securite"}>
        <div className={"conteneur-section-securite1"}>
          <img alt="" className="icone-Sag-securite" src={Icone_SAG_securite} aria-hidden="true"/>
          {t("sqin_ux.footer_renseignements_proteges")}
        </div>

        <div className={"conteneur-section-securite2"}>
          <a
            href={t("sqin_ux.footer_lien_conseils_cybersecurite")}
            target="_blank"
          >
            {t("sqin_ux.footer_text_conseils_cybersecurite")} &nbsp;
            <img src={iconeLienExterneCouleur} />
          </a>
        </div>
      </div>
      <div className={"premiere-section"}>
        <div className={"premiere-section-conteneur"}>
          {this_props.premiereSectionColonne1.map((section, index) => (
            <div key={0} className={"sous-section-premiere-section"}>
              {section.titre && <TitreAvecOuSansLien {...section.titre} />}

              <nav aria-label={"Pied de page"} className={"conteneur-lien"}>
                <ul>
                  {section.liens.map((lien, indexLien) => (
                    <li key={indexLien}>
                      <span className="lienCompose">
                        {lien.libelle === t("sqin_ux.header_title")
                          ? "S'informer sur le "
                          : ""}
                      </span>
                      <a
                        href={t(lien.url)}
                        className={"lien-pied-de-page"}
                        target="_blank"
                      >
                        {t(lien.libelle)}
                        {lien.afficherIconeLienExterne && (
                          <img
                            src={iconeLienExterne}
                            alt="Cet hyperlien s'ouvrira dans une nouvelle fenêtre."
                          />
                        )}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          ))}
          <div id="nous_joindre" className={"sous-section-deuxieme-section"}>
            {this_props.premiereSectionColonne2.map((section, index) => (
              <div key={index}>
                {section.titre && <TitreAvecOuSansLien {...section.titre} />}
                <div aria-label={"Pied de page"} className={"conteneur-lien"}>
                  {section.iconeEtLibelle.map((icone, indexIcone) => (
                    <div key={indexIcone} className={"ligne-info"}>
                      <div>
                        <img
                          className="icone-section-deuxieme-section"
                          src={icone.icone}
                        />
                      </div>
                      <div>
                        {icone.libelle.map((texte, indexTexte) => (
                          <div className={"infoDetail"} key={indexTexte}>
                            {t(texte)}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={"deuxieme-section"}>
        <nav className={"nav-deuxieme-section"} aria-label={"Légal"}>
          <ul className={"section-liens-deuxieme-section"}>
            {this_props.liensDeuxiemeSection.map((lien, index) => {
              return i18n.language === "fr" &&
                lien.libelle === "sqin_ux.footer_exceptions" ? (
                ""
              ) : this_props.liensDeuxiemeSection.length - 1 > index ? (
                <li key={index} className={"li_marge_droit"}>
                  <a
                    href={t(lien.url)}
                    className={"lien-pied-de-page"}
                    target={lien.afficherIconeLienExterne ? "_blank" : ""}
                  >
                    {" "}
                    {t(lien.libelle)}{" "}
                    {lien.afficherIconeLienExterne && (
                      <img
                        src={iconeLienExterneCouleur}
                        alt="Cet hyperlien s'ouvrira dans une nouvelle fenêtre."
                      />
                    )}
                  </a>
                </li>
              ) : (
                <li key={index}>
                  <a
                    href={t(lien.url)}
                    className={"lien-pied-de-page"}
                    target={lien.afficherIconeLienExterne ? "_blank" : ""}
                  >
                    {" "}
                    {t(lien.libelle)}{" "}
                    {lien.afficherIconeLienExterne && (
                      <img
                        src={iconeLienExterneCouleur}
                        alt="Cet hyperlien s'ouvrira dans une nouvelle fenêtre."
                      />
                    )}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
        <div className={"pied_logo"}>
          <a
            href={this_props.logo.url}
            rel="noreferrer"
            className={"lien-pied-de-page"}
          >
            <img
              className={"logo-pied-de-page"}
              alt={t(this_props.logo.alt)}
              src={this_props.logo.logo}
            />
          </a>
          <a
            href={this_props.copyright.url}
            rel="noreferrer"
            className={"lien-pied-de-page copy"}
            target="_blank"
          >
            {t(this_props.copyright.libelle) + ", " + new Date().getFullYear()}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default PiedDePage;
