import React from "react";
import { Tabs, Tab, Box, Grid } from "@mui/material";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

interface OngletsElemetsProps {
  elements: {
    titre: string;
    children: JSX.Element;
  }[];
}

const Onglets: React.FC<OngletsElemetsProps> = ({ elements }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={0} alignItems="flex-start">
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              {elements?.map((e, index) => (
                <Tab label={e.titre} {...a11yProps(index)} key={e.titre} />
              ))}
            </Tabs>
          </Box>
        </Grid>
      </Grid>
      {elements?.map((e, index) => (
        <TabPanel key={e.titre} value={activeTab} index={index}>
          {e.children}
        </TabPanel>
      ))}
    </Box>
  );
};

export default Onglets;
