import { BarreProgressionProps } from "./Etape";
import "../../style.css";
import imageCrochet from "../../icones/crochet-etape.svg";
import chevronHaut from "../../icones/chevron-haut.svg";
import chevronBas from "../../icones/chevron-bas.svg";
import "./BarreProgression.css";
import * as React from "react";

const BarreProgression = (props: BarreProgressionProps) => {
  const [newindex, setNewIndex] = React.useState(0);
  const [oldIndex, setOldIndex] = React.useState(0);
  const [active, setActive] = React.useState(false);
  const [active2, setActive2] = React.useState(false);

  const changeState = () => {
    props.etapes[newindex - 1].etat = "EnCours";
    const result = oldIndex - newindex;
    if (result === 1) {
      props.etapes[newindex].etat = "Neant";
      setOldIndex(newindex);
    } else if (result === 2) {
      props.etapes[newindex].etat = "Neant";
      props.etapes[newindex + 1].etat = "Neant";
      setOldIndex(newindex);
    } else if (result === 3) {
      props.etapes[newindex].etat = "Neant";
      props.etapes[newindex + 1].etat = "Neant";
      props.etapes[newindex + 2].etat = "Neant";
      setOldIndex(newindex);
    }
  };

  React.useEffect(() => {
    if (newindex === 1) {
      props.etapes[newindex - 1].etat = "EnCours";
      changeState();
      setOldIndex(newindex);
    } else if (newindex > oldIndex) {
      const result = newindex - oldIndex;
      if (result === 1) {
        props.etapes[oldIndex - 1].etat = "Succes";
        props.etapes[newindex - 1].etat = "EnCours";
        setOldIndex(newindex);
      }
    } else if (newindex < oldIndex) {
      changeState();
    }
  }, [newindex]);

  const sortedEtapes = [...props.etapes].sort((etape1, etape2) =>
    etape1.index > etape2.index ? 1 : -1
  );

  return (
    <div className="BarreConteneur">
      <a
        href="#"
        className={"processus mobile"}
        onClick={() => setActive(!active)}
      >
        <p>
          {" "}
          Étape {newindex} de {props.etapes.length}
        </p>
        <div>
          <span>{props.processus}</span>
          <i className={"chevron"}>
            <img src={active ? chevronHaut : chevronBas} alt="chevron" />
          </i>
        </div>
      </a>
      <a
        href="#"
        id={"zoneTitre"}
        className={active ? "active" : ""}
        onClick={() => setActive2(!active2)}
      >
        <div className="titreBarreProgression">
          <h3 className="titreBarre">{props.titre}</h3>
        </div>
        <i className={"chevron mobile"}>
          <img src={active2 ? chevronHaut : chevronBas} alt="chevron" />
        </i>
      </a>

      <div id="BarreConteneur" className={active || active2 ? "active" : ""}>
        {sortedEtapes.map(function (etape) {
          return (
            // eslint-disable-next-line
            <a
              className="zone-click"
              href="#"
              onClick={() => setNewIndex(etape.index)}
            >
              <div
                className={"ligne " + etape.etat}
                id={etape.index === 1 ? "debut" : ""}
                key={etape.index}
              >
                <div className={"puce " + etape.etat}>
                  {etape.etat !== "Succes" && (
                    <div className="textePuce">{etape.index}</div>
                  )}
                  {etape.etat === "Succes" && (
                    <div className="textePuce">
                      <img src={imageCrochet} className="imgV" alt="OK" />
                    </div>
                  )}
                </div>
              </div>
              <div className={"texteEtape " + etape.etat}>{etape.libelle}</div>
            </a>
          );
        })}
      </div>
    </div>
  );
};
export default BarreProgression;
