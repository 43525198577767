import LogoQuebecCouleur from "../../logos/logo_Quebec_couleur.svg";
import iconeCalendrier from "../../icones/calendrier.svg";
import iconeHorloge from "../../icones/horloge.svg";
import iconeTelephone from "../../icones/telephone.svg";
import { PiedDePageProps } from "./PiedDePageProps";

const piedDePageParDefaut: PiedDePageProps = {
  premiereSectionColonne1: [
    {
      titre: {
        libelle: "sqin_ux.header_title",
      },
      liens: [
        {
          libelle: "sqin_ux.footer_sinformer",
          afficherIconeLienExterne: true,
          url: "sqin_ux.footer_sinformer_lien",
        },
        {
          libelle: "sqin_ux.footer_prevention_fraude",
          afficherIconeLienExterne: true,
          url: "sqin_ux.footer_prevention_fraude_lien",
        },

        {
          libelle: "sqin_ux.footer_consentement",
          afficherIconeLienExterne: true,
          url: "sqin_ux.footer_consentement_lien",
        },
        {
          libelle: "sqin_ux.footer_plaintes",
          afficherIconeLienExterne: true,
          url: "sqin_ux.footer_plaintes_lien",
        },
      ],
    },
  ],

  premiereSectionColonne2: [
    {
      titre: {
        libelle: "sqin_ux.footer_nous_rejoindre",
        url: "#/",
      },
      iconeEtLibelle: [
        {
          icone: iconeCalendrier,
          libelle: ["sqin_ux.footer_lundi_vendredi"],
        },
        {
          icone: iconeHorloge,
          libelle: ["sqin_ux.footer_horaire"],
        },
        {
          icone: iconeTelephone,
          libelle: ["sqin_ux.footer_num_tel1", "sqin_ux.footer_num_tel2"],
        },
      ],
    },
  ],
  liensDeuxiemeSection: [
    {
      libelle: "sqin_ux.footer_accessibilite",
      afficherIconeLienExterne: true,
      url: "sqin_ux.footer_accessibilite_lien",
    },
    {
      libelle: "sqin_ux.footer_politique_conf",
      afficherIconeLienExterne: true,
      url: "sqin_ux.footer_politique_conf_lien",
    },
    {
      libelle: "sqin_ux.footer_condition",
      afficherIconeLienExterne: true,
      url: "sqin_ux.footer_condition_lien",
    },
    {
      libelle: "sqin_ux.footer_exceptions",
      afficherIconeLienExterne: true,
      url: "sqin_ux.footer_exceptions_lien",
    },
  ],
  logo: {
    logo: LogoQuebecCouleur,
    alt: "sqin_ux.footer.accessibilite.logo_alt",
    url: "https://www.quebec.ca/",
  },
  copyright: {
    libelle: "sqin_ux.footer_lien_gouv",
    url: "https://www.quebec.ca/droit-auteur",
  },
};

export default piedDePageParDefaut;
