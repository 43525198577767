import "./style.css";
import "../../style.css";

import ICaseACocherProps from "./CaseACocherProps";
import { useState, useEffect } from "react";
import Infobulle from "../Infobulle/Infobulle";

const CaseACocher = (props: ICaseACocherProps) => {
  const [checked, setCheckedValue] = useState(props.checked || false);
  const enErreur = !checked && props.messageErreur;
  const style = ["conteneurDiv"];
  if (props.type === "cu") style.push("conditionUtilisation");
  if (enErreur) style.push("caseACocherErreur");

  useEffect(() => {
    setCheckedValue(props.checked || false);
  }, [props.checked]);

  function handleChange(e: any) {
    if (props.onChange) {
      props.onChange(e);
    }
    setCheckedValue(!checked);
  }

  /*genere un id et un name si ne sont pas dans les props*/
  const id = props.id ? props.id : props.children?.toString();
  const name = props.name ? props.name : "case" + id;

  const input = (
    <div
      data-testid="CaseACocherComponent"
      className="conteneurCaseACocherInfobulle"
    >
      <div className="conteneurCaseACocher">
        <input
          data-testid="testCaseACocher"
          type="checkbox"
          id={id}
          name={name}
          value={props.value ? props.value : "on"}
          disabled={props.disabled}
          checked={checked}
          onChange={handleChange}
          aria-describedby={enErreur ? "alerte" : id}
        />
        <label htmlFor={id} className="libelle">
          {props.children}
        </label>
      </div>
      {props.infoBulleProps && (
        <div className="infobulleAdaptation">
          <div className="conteneur_aide">
            <Infobulle {...props.infoBulleProps}>
              {props.infoBulleProps}
            </Infobulle>
          </div>
        </div>
      )}
    </div>
  );

  const messageErreur = (
    <label id="alerte" className="messageErreur">
      {props.messageErreur}
    </label>
  );

  /*Construction du component */
  if (!props.type || props.type === "standard") {
    return input;
  } else {
    return (
      <div>
        <div className={style.join(" ")}>{input}</div>
        {enErreur ? messageErreur : ""}
      </div>
    );
  }
};
export default CaseACocher;
