import NotificationContextuelleProps from "./NotificationContextuelleProps";
import "./style.css";
import "../../style.css";
import fermerNotif from "../../icones/x-fermer.svg";
import * as React from "react";

const NotificationContextuelle = (props: NotificationContextuelleProps) => {
  const [className, setClassName] = React.useState(props.classname);

  return (
    <>
      <div
        data-testid="notificationComponent"
        className={"notification-contextuelle " + props.etat}
      >
        <div className="conteneur-notification">
          <p aria-live="polite" className="notification-contextuelle-typo">
            {props.message}
          </p>

          <button
            aria-label="Fermer"
            onClick={props.onClick}
            className={"image-bouton " + props.etat}
          >
            <img src={fermerNotif} alt="Fermer" />
          </button>
        </div>
      </div>
    </>
  );
};
export default NotificationContextuelle;
