import * as React from "react";
import "../../style.css";
import "./style.css";
import BoutonProps from "./BoutonProps";

const Bouton = ({
  type,
  onClick,
  children,
  reverse,
  iconBouton,
  iconBoutonGauche,
  ...rest
}: BoutonProps & React.HTMLAttributes<HTMLOrSVGElement>) => {
  const styleClass = type ? ["btn", `btn-${type}`] : ["btn", "btn-principal"];
  if (reverse) styleClass.push("inversee");

  return (
    <button data-testid="BoutonComponent" className={styleClass.join(" ")} onClick={onClick} {...rest}>
      {iconBoutonGauche ? <img className="icon" src={iconBoutonGauche} alt="" /> : null}
      {children}
      {iconBouton ? <img className="icon" src={iconBouton} alt="" /> : null}
    </button>
  );
};

export default Bouton;
